
















































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import formatMixin from '@/mixins/formatMixin';
import draftMixin from '@/mixins/draftMixin';
import Document from '@/models/Document/Document';
import gsap from 'gsap';
import colors from 'vuetify/es5/util/colors';
import ImgWithProgress from '@/components/Common/ImgWithProgress.vue';
import PictureSizes from '@/models/Picture/PictureSizes';
export default Vue.extend({
  name: 'DocumentTableData',

  mixins: [formatMixin, draftMixin],
  props: ['scrollToDocumentId', 'document', 'disabled'],
  components: {
    ImgWithProgress
  },
  mounted() {
    /*if (this.scrollToDocumentId === this.document.id) {
      gsap.to('.scrolledTo', { duration: 2, backgroundColor: 'transparent', opacity: 1 });
    }*/
  },
  computed: {
    ...mapGetters({
      getPictureSrcById: 'pictures/srcById',
      getPicturesrcByIdAndSize: 'pictures/srcByIdAndSize'
    } as any),
    scrolledTo() {
      return this.scrollToDocumentId === this.document.id;
    },
    pictureSizes() {
      return PictureSizes;
    }
  },
  methods: {
    ...(mapActions({
      toggleToSend: 'documents/toggleToSend',
      deleteDocument: 'documents/delete'
    }) as any),
    getFirstPictureSrcByIdAndSize(src: any, size: any): any {
      for (let i = 0; i < this.document.pictureIds.length; i++) {
        if (
          this.getPicturesrcByIdAndSize(this.document.pictureIds[i], PictureSizes.SMALL).indexOf('not-found.png') ===
            -1 &&
          this.getPicturesrcByIdAndSize(this.document.pictureIds[i], PictureSizes.SMALL).indexOf('no-photo.png') === -1
        ) {
          return this.getPicturesrcByIdAndSize(this.document.pictureIds[i], PictureSizes.SMALL);
        }
      }
      return this.getPicturesrcByIdAndSize(this.document.pictureIds[0], PictureSizes.SMALL);
    },
    getCommentTextClasses(baseClass: string, document: Document) {
      const classes = [baseClass];
      if (document.verified === true) {
        classes.push('primary--text');
      } else {
        classes.push('black--text');
      }
      return classes;
    },
    getTotalTextClasses(baseClass: string, document: Document) {
      const classes = [baseClass];
      if (!document.redFlag && !document.verified && !document.approved) {
        classes.push('black--text');
      }
      if (!document.redFlag && document.verified) {
        classes.push('primary--text');
      } else if (!document.redFlag && document.approved) {
        classes.push('yellow--text');
        classes.push('text--accent-4');
      }
      if (document.redFlag) {
        classes.push('red--text');
      }
      return classes;
    },

    handleCheckboxClick(document: Document) {
      this.toggleToSend(document);
    },
    handleDelete(document: Document) {
      const ok = confirm('Are you sure you want to delete this item?');
      if (ok === true) {
        this.deleteDocument(document);
      }
    },
    handleDocumentClick(documentId: string) {
      this.$emit('handleDocumentClick', documentId);
    },
    handlePictureClick(document: Document) {
      this.$router.push({
        name: 'Picture',
        params: { pictureId: document.pictureIds[0] }
      });
    }
  }
});
